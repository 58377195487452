import type { TranslateResult } from 'vue-i18n'
import type { LocaleType } from 'har-shared/types/common'
import type { Dayjs } from 'dayjs'
import type { LocaleMessageSchema } from '@/locale/types'

type Paths<T> = T extends Array<infer U>
  ? `${Paths<U>}`
  : T extends object
    ? {
        [K in keyof T & (string | number)]: K extends string
          ? `${K}` | `${K}.${Paths<T[K]>}`
          : never
      }[keyof T & (string | number)]
    : never

export const DateFormats = {
  short: 'L',
  mid: 'LL',
  long: 'dddd, LL h:mm A'
}

export function parseLocalDate(str: string | Date, fallbackLocale?: string): Dayjs | null {
  const dayjs = useDayjs()
  const resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
  const detectedLocale = resolvedOptions?.locale ?? fallbackLocale
  dayjs.locale(detectedLocale)
  let readyStr = str
  // Check if date includes time and is missing any timezone definition methods
  if (
    typeof str === 'string' &&
    str.includes(':') &&
    !str.includes('+') &&
    !str.includes('-') &&
    !str.endsWith('Z')
  ) {
    readyStr = `${readyStr}Z`
  }
  const obj = dayjs(readyStr, { locale: detectedLocale })
  return obj.isValid() ? obj.utc().tz(dayjs.tz.guess()) : null
}

export function useLocale() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, d, locale: gLocale, ...rest } = useI18n<LocaleMessageSchema, LocaleType>()

  const newT = t as (key: Paths<LocaleMessageSchema>, params?: any) => TranslateResult
  const newD = (str: string | Date, format: 'long' | 'mid' | 'short' = 'long') => {
    const obj = parseLocalDate(str, gLocale.value)
    return obj ? obj.format(DateFormats[format]) : ''
  }
  return { t: newT, d: newD, locale: gLocale, ...rest }
}
